<template>
  <div class="product-information">
    <div class="information-box centered-md desktop-only">
      <span class="product-amount" v-if="product.minSize > 1 && product.maxSize">{{ product.minSize | commarize | currency }}-{{ product.maxSize | commarize | currency }}</span>
      <span class="product-amount" v-else-if="product.maxSize">{{ product.maxSize | commarize | currency }}</span>
      <span class="product-provider">{{ product.productName }}</span>
      <span class="product-type hidden-md grant">Grant</span>
    </div>
    <div class="information-box centered-md desktop-only" v-if="product.closingDate">
      <span class="product-general-bold">{{ product.closingDate | dateFormat }}</span>
      <span class="product-general">Closing date</span>
    </div>
    <!-- Mobile information -->
    <div class="information-box mobile-only grant" v-if="product.minSize > 0 || product.maxSize > 0">
      <span class="product-amount" v-if="product.minSize > 1 && product.maxSize">{{ product.minSize | commarize | currency }}-{{ product.maxSize | commarize | currency }}</span>
      <span class="product-amount" v-else-if="product.maxSize">{{ product.maxSize | commarize | currency }}</span>
      <span class="product-provider">{{ product.productName }}</span>
    </div>
    <div class="btn btn-primary view-details" @click="$emit('view-product', product)">
      View Details
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    viewDetails: {
      type: Boolean,
      default: true
    }
  }
}
</script>
