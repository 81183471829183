<template>
  <div class="product-categories-list">
    <ProductCategoryCard
      v-for="productCategory in productCategories" :key="productCategory.routeName"
      :icon="productCategory.icon" :title="productCategory.title" :routeName="productCategory.routeName" :categoryType="productCategory.productType"
    />
  </div>
</template>

<script>
import ProductCategoryCard from './ProductCategoryCard'
export default {
  components: { ProductCategoryCard },
  props: {
    productCategories: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.product-categories-list {
  display: grid;
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  margin: 20px 0;
  padding: 4px 0;
}
</style>
