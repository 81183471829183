<template>
  <div class="product-category-card">
    <div class="product-category-card-header">
      <img :src="require(`@/assets/images/products/${icon}.svg`)" alt="product icon" />
      {{ title }}
    </div>
    <button @click="view(routeName, categoryType)" :data-cy="routeName" class="btn btn-primary btn-sm">View</button>
  </div>
</template>

<script>
import { amplitudeEvents } from '@/utils/constants'

export default {
  name: 'ProductCategoryCard',
  props: {
    icon: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    routeName: {
      type: String,
      required: true
    },
    categoryType: {
      type: String,
      required: true
    }
  },
  methods: {
    view(routeName, productType) {
      this.$router.push({ name: routeName })
      if (productType === 'credit-score') {
        this.$ma.trackEvent({
          eventType: amplitudeEvents.CreditScore.CHC_PRODUCT_CLICK
        })
      } else {
        this.$ma.trackEvent({
          eventType: '<Classic><SME><OnboardingProduct><View><Button>',
          eventProperties: {
            productType
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.product-category-card {
  border: 1px solid var(--color-secondary-50);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 18px 0;
  transition: all 0.5s;

  &:hover {
    box-shadow: $lift-box-shadow;
    border-color: var(--color-secondary-200);
    transform: scale(1.05);
  }

  .product-category-card-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
    color: var(--color-primary-500);
    font-size: 14px;
    text-align: center;
    img {
      margin-bottom: 4px;
    }
  }

  button.btn {
    width: 85px;
    height: 24px;
    padding: 0;
    align-self: center;
    margin: 12px 38px;
  }
}
</style>
