<template>
  <div class="product-information">
    <div class="information-box centered-md">
      <span class="product-general-bold">{{ product.productName }}</span>
      <span class="product-general">{{ product.tradingName }}</span>
    </div>
    <!-- Desktop information -->
    <div class="information-box centered-md desktop-only">
      <span class="product-amount" v-if="product.minSize > 1 && product.maxSize">{{ product.minSize | commarize | currency }} - {{ product.maxSize | commarize | currency }}</span>
      <span class="product-amount" v-else-if="product.maxSize">{{ product.maxSize | commarize | currency }}</span>
      <span class="product-type loan">{{ product.subcategoryName }}</span>
    </div>
    <div class="information-box centered-md desktop-only">
      <span class="product-general-bold" v-if="product.aprMin">{{ product.aprMin | numberSeparator }}%</span>
      <span class="product-general-bold" v-else>0%</span>
      <span class="product-general">Rates from</span>
    </div>
    <div class="information-box centered-md desktop-only" v-if="product.speed">
      <span class="product-general-bold">{{ opportunitySpeedDic[product.speed] }}</span>
      <span class="product-general">Decision time</span>
    </div>
    <div class="information-box centered-md desktop-only" v-if="product.type === 'liveoffer'">
      <span class="product-general-bold instant-eligibility">INSTANT ELIGIBILITY</span>
    </div>
    <!-- Mobile information -->
    <div class="information-box mobile-only">
      <span class="product-amount" v-if="product.minSize > 1 && product.maxSize">{{ product.minSize | commarize | currency }} - {{ product.maxSize | commarize | currency }}</span>
      <span class="product-amount" v-else-if="product.maxSize">{{ product.maxSize | commarize | currency }}</span>
    </div>
    <div class="information-box mobile-only">
      <span class="product-general apr-rates-mobile" v-if="product.aprMin">Rates from {{ product.aprMin | numberSeparator }}%</span>
      <span class="product-general apr-rates-mobile" v-else>APR: 0%</span>
    </div>
    <div class="btn btn-primary view-details" @click="$emit('view-product', product)" v-if="product.type === 'liveoffer'">
      View Details
    </div>
    <span class="product-type loan mobile-only">{{ product.subcategoryName }}</span>
    <div
      class="btn btn-primary view-details"
      :class="{ 'has-status': product.applicationStatusName }"
      @click="$emit('view-product', product)"
      v-if="product.type !== 'liveoffer' && this.viewDetails"
    >
      {{ product.applicationStatusName ? product.applicationStatusName : 'View details' }}
    </div>
  </div>
</template>

<script>
import { opportunitySpeedDic } from '@/utils/constants'

export default {
  data() {
    return {
      opportunitySpeedDic
    }
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    viewDetails: {
      type: Boolean,
      default: true
    }
  }
}
</script>
