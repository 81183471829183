var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"container-header"},[_c('h1',[_vm._v("Commercial Mortgage")]),_c('Contact')],1),(_vm.locked)?_c('div',{staticClass:"card"},[_c('p',[_vm._v(" Thank you for your commerical mortgage enguiry. We have access to the whole market of available lenders. One of our funding experts will be in touch with you directly. ")]),_c('p',[_vm._v(" If you have any immediate questions you can get in touch with us below. ")]),_c('h2',[_vm._v("In the meantime, check out some of our other products")]),_c('ProductCategoryList',{attrs:{"productCategories":[
        {
          icon: 'b-current-account',
          title: 'Compare business current accounts',
          routeName: 'business-current-account'
        },
        {
          icon: 'foreign-exchange',
          title: 'Save on international money transfers',
          routeName: 'foreign-exchange'
        },
        {
          icon: 'insurance',
          title: 'Tailored insurance',
          routeName: 'insurance'
        },
        {
          icon: 'energy',
          title: 'Compare energy prices',
          routeName: 'energy'
        }
      ]}}),_c('button',{staticClass:"btn btn-transparent overview-button"},[_vm._v(" Go to overview ")])],1):_vm._e(),_c('ProductList',{attrs:{"title":"Grant matches","subtitle":"Grants matches from our funder network.","products":_vm.products,"locked":_vm.locked},scopedSlots:_vm._u([{key:"locked",fn:function(){return [_c('ProductListLocked',{attrs:{"lockedTitle":"Unlock Mortgage","icon1":"grants","lockedRouteName":"commercial-mortgagte-form","creditScore":false}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }