<template>
  <!-- groupedMatches layout -->
  <div class="product-list-container" v-if="showFilteredTiles && getQueryParamsForTesting">
    <span>
      <h1 v-if="title" :class="[{ hidden: hideProductListContainer }, 'product-title']">
        {{ title }}
      </h1>
    </span>
    <div class="ui active inverted dimmer page-loader" v-if="loading">
      <div class="ui medium text loader">
        <spinner :size="64" :loader="true" color />
      </div>
    </div>
    <slot name="product-list-locked" v-else-if="locked" />
    <div class="products-list" v-else>
      <div class="loading">
        <slot name="product-list-loading" v-if="!allProvidersResponded" />
      </div>
      <ProductTile v-for="product in products" :product="product" :displayApplyButton="true" :key="product.opportunityId" @view-product="product => viewProduct(product)" />
      <button class="btn btn-transparent see-more" v-if="seeMore && !hideProductListContainer && products.length === 2" @click="$emit('see-more')">SEE MORE</button>
      <button class="btn btn-transparent see-more" v-if="seeMore && !hideProductListContainer && products.length > 2" @click="$emit('see-more')">SEE LESS</button>
      <div v-if="!error && Array.isArray(products) && !products.length">
        <slot name="product-list-empty" />
      </div>
      <div v-else-if="error">
        <slot name="product-list-error" />
      </div>
    </div>
    <PageOverlay :overlayOpen="overlayOpen" @close-overlay="closeOverlay">
      <slot name="product-detail" :product="product" v-if="product" />
    </PageOverlay>
  </div>

  <!-- Original product page layout -->
  <div class="product-list-container" v-else-if="!getQueryParamsForTesting">
    <div class="product-list-container">
      <h1 class="product-title" v-if="title">{{ title }}</h1>
      <p class="product-subtitle" v-if="subtitle">{{ subtitle }}</p>

      <div class="ui active inverted dimmer page-loader" v-if="loading">
        <div class="ui medium text loader">
          <spinner :size="64" :loader="true" color />
        </div>
      </div>

      <slot name="product-list-locked" v-else-if="locked" />
      <div class="products-list" v-else>
        <div class="loading">
          <slot name="product-list-loading" v-if="!allProvidersResponded" />
        </div>
        <ProductTile
          v-for="product in products.slice(0, 3)"
          :product="product"
          :displayApplyButton="true"
          :key="product.opportunityId"
          @view-product="product => viewProduct(product)"
        />
        <div class="card-container" v-if="isLoan">
          <InsightsCard v-for="insight in insights" :key="insight.id" :insight="insight" @refetch-products="refetchProducts" :companyId="companyId" />
        </div>
        <template v-if="seeMore && products.length > 3">
          <ProductTile
            v-for="product in products.slice(3)"
            :product="product"
            :displayApplyButton="true"
            :key="product.opportunityId"
            @view-product="product => viewProduct(product)"
          />
        </template>
        <button class="btn btn-transparent see-more" v-if="seeMore && products.length === 3" @click="$emit('see-more')">SEE MORE</button>
        <button class="btn btn-transparent see-more" v-if="seeMore && products.length > 3" @click="$emit('see-more')">SEE LESS</button>
        <div v-if="!error && Array.isArray(products) && !products.length">
          <slot name="product-list-empty" />
        </div>
        <div v-else-if="error">
          <slot name="product-list-error" />
        </div>
      </div>
      <PageOverlay :overlayOpen="overlayOpen" @close-overlay="closeOverlay">
        <slot name="product-detail" :product="product" v-if="product" />
      </PageOverlay>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/Spinner'
import ProductTile from './ProductTile'
import PageOverlay from '@/components/PageOverlay'
import InsightsCard from '@product/features/products/loan/insightsCard'

export default {
  components: {
    Spinner,
    PageOverlay,
    ProductTile,
    InsightsCard
  },
  props: {
    title: {
      type: String
    },
    locked: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: [Boolean, Error],
      default: false
    },
    subtitle: {
      type: String
    },
    products: {
      type: Array
    },
    openUnlockOverlay: {
      type: Function
    },
    seeMore: {
      type: Boolean,
      default: false
    },
    closeOverlayFlag: {
      type: Boolean,
      default: false
    },
    allProvidersResponded: {
      type: Boolean,
      default: false
    },
    productSubcategory: {
      type: String
    },
    selectedSubcategory: {
      type: String
    },
    hideProductListContainer: {
      type: Boolean
    },
    getQueryParamsForTesting: {
      type: String
    },
    insights: {
      type: Array
    },
    companyId: {
      type: String
    }
  },
  data() {
    return {
      overlayOpen: false,
      product: null
    }
  },
  methods: {
    closeOverlay() {
      this.overlayOpen = false
      this.product = null
      this.$emit('close-product-details')
    },
    viewProduct(product) {
      this.product = product
      if (process.env.TENANT !== 'aus' && product.route) {
        this.$router.push(product.route)
      } else {
        this.overlayOpen = true
      }
      this.$emit('view-product', product)
    },
    refetchProducts(value) {
      this.$emit('refetch-products', value)
    }
  },
  computed: {
    isLoan() {
      return this.$route.name === 'loan-matches'
    },
    showFilteredTiles() {
      return !(this.selectedSubcategory !== 'all' && this.selectedSubcategory !== this.productSubcategory)
    }
  },
  watch: {
    closeOverlayFlag(value) {
      value && this.closeOverlay()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.product-list-container {
  margin: 30px 0;

  /deep/ h1.product-title {
    color: var(--color-primary-500);
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 0;
    margin-left: 8px;

    &:before {
      content: '';
      border-left: 3px solid var(--color-secondary-500);
      margin-right: 8px;
    }
  }

  p.product-subtitle {
    white-space: break-spaces;
    margin-left: 8px;
  }

  button.see-more {
    width: 100%;
    font-size: 12px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-primary-500);
    background-color: $color-white;
    border: 1px solid var(--color-secondary-50);

    &:hover {
      border-color: var(--color-primary-400);
      background-color: var(--color-secondary-50);
      color: var(--color-primary-500);
    }
  }

  .products-list {
    @media only screen and (max-width: 1020px) {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .loading {
    margin-bottom: 20px;
  }

  .material-icons {
    vertical-align: middle;
  }

  .hidden {
    display: none;
  }
}

.card-container {
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  gap: 20px;
  @media only screen and (max-width: $breakpoint-md-max) {
    flex-direction: column;
  }
}
</style>
