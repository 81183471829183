<template>
  <div>
    <div class="container-header">
      <h1>Commercial Mortgage</h1>
      <Contact />
    </div>
    <div class="card" v-if="locked">
      <p>
        Thank you for your commerical mortgage enguiry. We have access to the whole market of available lenders.
        One of our funding experts will be in touch with you directly.
      </p>
      <p>
        If you have any immediate questions you can get in touch with us below.
      </p>
      <h2>In the meantime, check out some of our other products</h2>
      <ProductCategoryList
        :productCategories="[
          {
            icon: 'b-current-account',
            title: 'Compare business current accounts',
            routeName: 'business-current-account'
          },
          {
            icon: 'foreign-exchange',
            title: 'Save on international money transfers',
            routeName: 'foreign-exchange'
          },
          {
            icon: 'insurance',
            title: 'Tailored insurance',
            routeName: 'insurance'
          },
          {
            icon: 'energy',
            title: 'Compare energy prices',
            routeName: 'energy'
          }
        ]"
      />

      <!--Overview button does nothing at the moment-->
      <button class="btn btn-transparent overview-button">
        Go to overview
      </button>
    </div>
    <ProductList
      title="Grant matches"
      subtitle="Grants matches from our funder network."
      :products="products"
      :locked="locked"
    >
      <template v-slot:locked>
        <ProductListLocked
          lockedTitle="Unlock Mortgage"
          icon1="grants"
          lockedRouteName="commercial-mortgagte-form"
          :creditScore="false"
        />
      </template>
    </ProductList>
  </div>
</template>

<script>
import ProductList from '@product/components/products/ProductList'
import ProductCategoryList from '@product/components/products/ProductCategoryList'

export default {
  components: { ProductList, ProductCategoryList },
  props: {
    locked: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.title {
  width: 900px;

  .bold {
    color: var(--color-primary-500);
  }
}
</style>
