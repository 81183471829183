<template>
  <div :class="[{ hidden: hide }, 'card']">
    <div class="copy">
      <div class="svg-icon">
        <!-- Ireland is under UK environment -->
        <div v-if="isIreland">
          <img v-if="fundingRequired" :src="require(`@/assets/images/icons/${euroIcons.fundingRequiredIcon}.svg`)" alt="funding required icon" />
          <img v-else :src="require(`@/assets/images/icons/${euroIcons.decisionTimeIcon}.svg`)" alt="funding required icon" />
        </div>
        <div v-else>
          <img v-if="fundingRequired" :src="require(`@/assets/images/icons/${icon.fundingRequiredIcon}.svg`)" alt="decision time icon" />
          <img v-else :src="require(`@/assets/images/icons/${icon.decisionTimeIcon}.svg`)" alt="decision time icon" />
        </div>
      </div>
      <div class="text">
        <template>
          <div v-if="fundingRequired" class="title">Funding required</div>
          <div v-else class="title">Decision time</div>
        </template>
        <template>
          <div v-if="fundingRequired" class="description">
            Adjust your funding required amount by {{ setOperator(insight.operator) }} {{ currencyIcon }}{{ stringToNumber(insight.value) }} to see
            {{ insight.newMatchesCount }} other products.
          </div>
          <div class="description" v-else>
            If you increase the timing when you need the funding to {{ opportunitySpeedDic[insight.value] }} you will see {{ insight.newMatchesCount }} more matches.
          </div>
        </template>
      </div>
    </div>
    <div class="buttons">
      <button class="btn" @click="handleClick(insight)">
        <em class="icon material-icons">lightbulb_rounded</em>
        <span v-if="fundingRequired">Adjust to {{ currencyIcon }}{{ stringToNumber(insight.valueAfterUpdate) }}</span>
        <span v-else>Increase to {{ opportunitySpeedDic[insight.value] }}</span>
        <em class="icon material-icons">arrow_forward</em>
      </button>
      <button class="link" @click="hideCard(insight)">Not interested</button>
    </div>
  </div>
</template>

<script>
import { opportunitySpeedDic, fundingRequiredInsightId, decisionTimeInsightId, amplitudeEvents } from '@/utils/constants'
import config from '@/config/global'
import { updateInsights, getInsights } from '@product/api/opportunity'
import { toastTopCenterOptions } from '@/config/vue-toast'
import { currentFormNameGetters } from '@/store/modules/currentFormDetails/routines'
import { mapGetters } from 'vuex'

export default {
  name: 'InsightsCard',
  props: {
    insight: {
      type: Object
    },
    companyId: {
      type: String
    }
  },
  data() {
    return {
      currencyIcon: config.whitelabel.country.currency.defaultCurrencySymbol,
      opportunitySpeedDic,
      hide: false,
      icon: {
        decisionTimeIcon: {
          'United Kingdom': 'pound_icon_circle',
          Australia: 'pound_icon_circle',
          Canada: 'pound_icon_circle'
        }[config.whitelabel.country.defaultCountry],
        fundingRequiredIcon: {
          'United Kingdom': 'hand_hold_pound_icon_circle',
          Australia: 'hand_hold_dollar_icon_circle',
          Canada: 'hand_hold_dollar_icon_circle'
        }[config.whitelabel.country.defaultCountry]
      },
      euroIcons: {
        decisionTimeIcon: 'euro_icon_circle',
        fundingRequiredIcon: 'hand_hold_euro_icon_circle'
      }
    }
  },
  computed: {
    ...mapGetters({
      currentFormName: currentFormNameGetters.CURRENT_FORM_NAME
    }),
    isUK() {
      return ['United Kingdom'].includes(config.whitelabel.country.defaultCountry)
    },
    fundingRequired() {
      return this.insight?.id === fundingRequiredInsightId
    },
    decisionTime() {
      return this.insight?.id === decisionTimeInsightId
    },
    isIreland() {
      return window.WHITE_LABEL_STYLES?.subdomain === 'ireland'
    }
  },
  methods: {
    async handleClick(insight) {
      await updateInsights(insight.performActionUrl)
      await this.$emit('refetch-products', this.currentFormName)
      await getInsights(this.companyId, this.currentFormName)
      this.showToastMessage(insight)
    },
    setOperator(opperator) {
      return opperator === '-' ? 'subtracting' : 'adding'
    },
    showToastMessage(insight) {
      this.$toasted.show(
        this.fundingRequired
          ? `Funding required has been updated ${this.currencyIcon}${insight.valueAfterUpdate}.`
          : `Decision time has been increas to  ${this.opportunitySpeedDic[insight.value]}`,

        { ...toastTopCenterOptions, type: 'success', icon: 'error_outline' }
      )
      this.sendTracking(insight)
      this.hideCard()
    },
    hideCard() {
      this.hide = true
    },
    stringToNumber(insight) {
      return Math.trunc(Number(insight))
    },
    sendTracking(insight) {
      if (insight === 'fundingRequired') {
        this.$ma.trackEvent({ eventType: amplitudeEvents.Matches.FUNDING_REQUIRED_INSIGHTS })
      } else {
        this.$ma.trackEvent({ eventType: amplitudeEvents.Matches.DECISION_TIME_INSIGHTS })
      }
      this.hide = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

.card {
  align-items: center;
  border: 1px solid var(--color-secondary-50);
  border-radius: 8px;
  background-color: $color-white;
  box-shadow: 0px 0px 1px rgba(0, 62, 82, 0.16), 0px 1px 3px rgba(0, 62, 82, 0.16);
  display: flex;
  justify-content: space-between;
  height: 130px;
  width: 100%;
  @media only screen and (max-width: $breakpoint-md-max) {
    flex-direction: column;
    height: auto;
  }

  .copy {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .text {
      line-height: 22px;
      @media only screen and (max-width: $breakpoint-md-max) {
        font-size: 14px;
      }
      .title {
        font-weight: bold;
        margin-bottom: 5px;
      }
      .description {
        font-size: 13px;
        @media only screen and (max-width: $breakpoint-md-max) {
          font-size: 13px;
        }
      }
    }

    .svg-icon {
      width: 200px;
      fill: $color-select-goals-icons-fill;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;

    @media only screen and (max-width: $breakpoint-md-max) {
      width: 100%;
      flex-direction: row;
      justify-content: space-around;
    }

    @media only screen and (max-width: $breakpoint-xs-max) {
      width: 100%;
      flex-direction: column;
    }

    .btn {
      background-color: var(--color-secondary-500);
      color: white;
      margin-bottom: 10px;
      white-space: nowrap;
      font-size: 12px;
      padding: 0px 10px;

      @media only screen and (max-width: $breakpoint-md-max) {
        margin: 10px 0px;
        align-items: center;
        padding: 0px;
      }

      &:hover {
        background: $color-primary;
      }
      span {
        margin: 15px;
        @media only screen and (max-width: $breakpoint-md-max) {
          margin: 5px;
        }
      }

      .material-icons {
        color: var(--color-warning-200);
        vertical-align: middle;
        font-size: 15px;
        width: 10px;
        @media only screen and (max-width: $breakpoint-md-max) {
          width: 20px;
        }
      }
    }

    .link {
      text-decoration: none;
      font-size: 12px;
      font-weight: bold;
      color: var(--color-secondary-500);
      &:hover {
        color: $color-primary;
      }
    }
  }
}
.hidden {
  display: none;
}
</style>
