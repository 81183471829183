<template>
  <div class="product-information" v-if="product.displayDetails">
    <div class="information-box centered-md">
      <span>You might be able to borrow...</span>
      <span class="product-amount">{{ loanAmount | numberSeparator | currency }}</span>
      <popover placement="top" trigger="hover click" class="popover" tabindex="-1">
        <span class="product-type hidden-md mir">Representative Offer</span>
        <template #popover>This is not an offer. This is what a loan with iwoca might look like, but they need more information from you to make an actual offer.</template>
      </popover>
    </div>
    <div class="mobile-tile">
      <div class="information-box centered-md mobile">
        <span class="product-general-bold">{{ Math.floor(totalRepaid) | numberSeparator | currency }}</span>
        <span class="product-general">Total Repaid</span>
      </div>
      <div class="information-box centered-md mobile">
        <span class="product-general-bold">{{ Math.floor(totalRepaid / 12) | numberSeparator | currency }}</span>
        <span class="product-general">Monthly repayments</span>
      </div>
      <div class="information-box centered-md mobile">
        <span class="product-general-bold">12 months</span>
        <span class="product-general">Duration</span>
      </div>
    </div>
  </div>
  <div class="product-information" v-else>
    <div v-if="product.status === 'InformationRequested'">
      <div class="information">
        We may be able to get you an offer from {{ product.providerName }}, but we need a little more information from you. Our team will contact you shortly.
      </div>
    </div>
    <div v-if="product.status === 'Qualified'">
      <div class="information">We are awaiting a decision from {{ product.providerName }}.</div>
    </div>
    <div class="btn btn-primary view-details" @click="$emit('view-product', product)" v-if="this.viewDetails">About {{ product.providerName }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { companyGetters } from '@/store/modules/company/routines'

export default {
  data() {
    return {
      company: null
    }
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    viewDetails: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters({
      companyId: companyGetters.COMPANY_ID
    }),
    loanAmount() {
      return Math.floor(this.product?.lastYearsTurnover * 0.1)
    },
    totalRepaid() {
      return this.loanAmount * 1.23
    }
  }
}
</script>

<style scoped lang="scss">
.information {
  display: flex;
  align-items: center;
  max-width: 700px;
}
</style>
