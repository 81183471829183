<template>
  <div class="product-information">
    <div v-if="applicationStatus && !product.displayDetails">
      <div class="information-box centered-md">{{ applicationStatusCopy | numberSeparator }}</div>
    </div>
    <div v-else class="product-information">
      <div class="information-box centered-md">
        <span class="product-provider">Merchant cash advance</span>
        <span class="product-amount">{{ product.cashAdvance | numberSeparator | currency }}</span>
        <popover placement="bottom" trigger="hover click" class="popover" tabindex="-1">
          <span class="product-type hidden-md mca">Quick Offer</span>
          <template #popover>
            Our Quick Offers are subject to further information, which you will need to share with the loan provider. Their offer may change subject to this information and final
            checks.
          </template>
        </popover>
      </div>
      <div class="mobile-tile">
        <div class="information-box centered-md mobile">
          <span class="product-general-bold">{{ product.loanAmount | numberSeparator | currency }}</span>
          <span class="product-general">Total repaid</span>
        </div>
        <div class="information-box centered-md mobile">
          <span class="product-general-bold">{{ product.repaymentSweep }}%</span>
          <span class="product-general">Repayment rate</span>
        </div>
        <div class="information-box centered-md mobile" v-if="product.displayDetails">
          <span class="product-general-bold">{{ product.factorRate.toFixed(2) }}</span>
          <span class="product-general">Factor rate</span>
        </div>
        <div class="information-box centered-md mobile">
          <span class="product-general-bold quote-expiry">{{ product.approvalValidUntil | dateFormat }}</span>
          <span class="product-general quote-expiry">Offer expiry</span>
        </div>
      </div>
    </div>
    <button type="button" :class="applicationStatus ? 'link view-details' : 'btn btn-primary view-details'" @click="$emit('view-product', product)" v-if="viewDetails">
      View details
    </button>
    <button class="btn btn-secondary view-details has-status" v-if="viewDetails && applicationStatus && applicationStatusIcons">
      {{ applicationStatus }} <span class="material-icons">{{ applicationStatusIcons }}</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
      required: true
    },
    viewDetails: {
      type: Boolean,
      default: true
    },
    applicationStatus: {
      type: String
    },
    applicationStatusIcons: {
      type: String
    },
    applicationStatusCopy: {
      type: String
    }
  }
}
</script>
