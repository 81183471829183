<template>
  <div class="product-tile" :class="{ [product.type]: true }" tabindex="5" @keyup.enter="viewProduct(product)">
    <div class="product-image">
      <img :src="productTileImage" alt="product icon" />
      <div class="mobile-only mobile-speed" v-if="product.speed"><span class="material-icons">access_time</span>{{ product.speedName }}</div>
    </div>
    <div class="product-information-wrapper">
      <Component
        :is="tileType"
        :product="product"
        :viewDetails="viewDetails"
        @view-product="viewProduct"
        :applicationStatusIcons="applicationStatusIcons"
        :applicationStatus="applicationStatus"
        :applicationStatusCopy="applicationStatusCopy"
        :data-cy="tileType"
      />
    </div>
  </div>
</template>

<script>
import LoanTile from './tiles/LoanTile'
import BusinessLoanOfferTile from './tiles/BusinessLoanOfferTile'
import GrantTile from './tiles/GrantTile'
import MerchantCashAdvanceTile from './tiles/MerchantCashAdvanceTile'
import ManualInterventionRequiredTile from './tiles/ManualInterventionRequiredTile'
import FibrTile from './tiles/FibrTile'
import { amplitudeEvents } from '@/utils/constants'
import { getWhitelabelNameFromSubdomain } from '@/utils/utils'

export default {
  components: {
    LoanTile,
    BusinessLoanOfferTile,
    MerchantCashAdvanceTile,
    GrantTile,
    ManualInterventionRequiredTile,
    FibrTile
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    viewDetails: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    tileType() {
      switch (this.product.type) {
        case 'liveoffer':
          return 'LoanTile'
        case 'loan':
          return 'LoanTile'
        case 'grant':
          return 'GrantTile'
        case 'mca':
          return 'MerchantCashAdvanceTile'
        case 'mir':
          return 'ManualInterventionRequiredTile'
        case 'rcf':
          return 'FibrTile'
        case 'business-loan-offer':
          return 'BusinessLoanOfferTile'
        default:
          return 'LoanTile'
      }
    },
    productTileImage() {
      if (this.product.logo) {
        return this.product.logo
      } else if (this.product.logoUri) {
        return this.product.logoUri
      } else if (this.product.providerName) {
        try {
          return require(`../../assets/images/partners/${this.product.providerName}-logo.svg`)
        } catch {
          return require('@/assets/images/logo-not-found.svg')
        }
      } else {
        return require('@/assets/images/logo-not-found.svg')
      }
    },
    applicationStatus() {
      switch (this.product.aggregatorProduct && this.product.status) {
        case 'AwaitingDocuments':
          return 'Awaiting Documents'
        case 'Offer':
          return 'Approved'
        case 'ClosedLost':
          return 'Declined'
        default:
          return ''
      }
    },
    applicationStatusIcons() {
      switch (this.product.aggregatorProduct && this.product.status) {
        case 'AwaitingDocuments':
          return 'description'
        case 'Offer':
          return 'done'
        case 'ClosedLost':
          return 'clear'
        default:
          return ''
      }
    },
    applicationStatusCopy() {
      switch (this.product.aggregatorProduct && this.product.status) {
        case 'AwaitingDocuments':
          return `${this.product.providerName} need more documents to approve your application for £${this.product.loanAmount}.`
        case 'Offer':
          return `${this.product.providerName} have approved your application for £${this.product.loanAmount}.`
        case 'ClosedLost':
          return `${this.product.providerName} have rejected your application for £${this.product.loanAmount}.`
        default:
          return ''
      }
    }
  },
  methods: {
    viewProduct(product) {
      this.$emit('view-product', product)
      if (getWhitelabelNameFromSubdomain() === 'lloydsbank') {
        this.$ma.trackEvent({
          eventType: amplitudeEvents.lloydsSMEGrants.CLICK_VIEW_DETAILS,
          eventProperties: {
            click_name: 'ViewDetails',
            opportunityId: product.opportunityId,
            productType: product.type,
            productName: product.productName,
            productVendor: product.providerName
          }
        })
      }

      if (product.aggregatorProduct) {
        this.$ma.trackEvent({
          eventType: amplitudeEvents.AggregatorApi.VIEW_PRODUCT,
          eventProperties: {
            ...product
          }
        })
      } else {
        this.$ma.trackEvent({
          eventType: amplitudeEvents.fundingCheckout.VIEW_MATCH_DETAILS,
          eventProperties: {
            opportunityId: product.opportunityId,
            productType: product.type,
            productName: product.productName,
            productVendor: product.providerName
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';

$breakpoint-tile-lg: 1400px + $sidebar-width;
$breakpoint-tile-md: 1250px + $sidebar-width;
$breakpoint-tile-sm: 1050px + $sidebar-width;
$breakpoint-tile-xs: 450px + $sidebar-width;

.product-tile {
  display: flex;
  flex-direction: row;
  height: 118px;
  transition: all 0.3s;
  border: 1px solid var(--color-secondary-50);
  background-color: $color-white;
  border-radius: 8px;
  margin-bottom: 16px;
  position: relative;
  width: 100%;
  box-shadow: 0px 0px 1px rgba(0, 62, 82, 0.16), 0px 1px 3px rgba(0, 62, 82, 0.16);

  @media only screen and (max-width: 400px) {
    flex-direction: column;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.04), 0 8px 16px 0 rgba(0, 0, 0, 0.06);
  }
  @media only screen and (max-width: $breakpoint-lg) {
    height: 100%;
  }

  &.liveoffer {
    border-color: var(--color-tertiary-400);
  }

  .product-image {
    position: relative;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 12px;
    font-weight: 500;
    width: 220px;

    @media only screen and (max-width: 1540px) {
      width: 180px;
    }

    img {
      max-width: 115px;
      object-fit: contain;
      height: 70px;

      @media only screen and (max-width: $breakpoint-tile-xs) {
        width: 100%;
        height: 80%;
      }
    }

    .mobile-speed {
      position: absolute;
      bottom: 10px;

      @media only screen and (max-width: 400px) {
        top: 10px;
        right: -30vw;
      }

      span.material-icons {
        font-size: 12px;
        margin-right: 2px;
        margin-bottom: 2px;
      }

      &.mobile-only {
        display: none;

        @media only screen and (max-width: $breakpoint-tile-sm) {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .product-information-wrapper {
    width: 100%;
    height: 100%;
    padding: 20px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-left: 1px solid var(--color-secondary-50);
    flex: 1;

    @media only screen and (max-width: 400px) {
      border-top: 1px solid var(--color-secondary-50);
      border-left: none;
    }

    /deep/.product-information {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;

      @media only screen and (max-width: $breakpoint-tile-sm) {
        justify-content: flex-start;
        flex-direction: column;
      }

      .btn-primary {
        &.pending {
          background-color: var(--color-primary-200);
        }
      }

      span.progress {
        width: 20px;
        height: 20px;
        position: absolute;
        border: 3px solid var(--color-secondary-50);
        right: 8px;
        top: 8px;
        border-radius: 50%;
        z-index: 10;
      }

      span.product-type {
        color: var(--color-warning-600);
        border: 1px solid var(--color-warning-600);
        border-radius: 12px;
        width: 140px;
        font-size: 12px;
        height: 24px;
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;

        &.mca {
          color: var(--color-primary-400);
          border-color: var(--color-primary-400);
        }

        &.grant {
          color: var(--color-primary-400);
          border-color: var(--color-primary-400);
        }

        @media only screen and (max-width: $breakpoint-tile-sm) {
          width: 140px;
          border-radius: 18px;
        }

        &.mobile-only {
          display: none;

          @media only screen and (max-width: $breakpoint-tile-sm) {
            display: flex;
            margin-top: 10px;
          }
        }
      }

      .information-box {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 10px;

        &:first-child {
          width: 140px;
        }

        @media only screen and (max-width: $breakpoint-tile-sm) {
          margin: 0;

          &:first-child {
            margin-bottom: 3px;
            width: 100%;
          }
        }

        @media only screen and (max-width: $breakpoint-tile-sm) {
          margin: 0;
        }

        &.mobile-only {
          display: none;

          @media only screen and (max-width: $breakpoint-tile-sm) {
            display: flex;
            flex-direction: row;

            &.grant {
              flex-direction: column;

              span.product-provider {
                white-space: normal;
              }
            }
          }

          .product-amount {
            width: 100%;
          }
        }

        &.desktop-only {
          display: flex;

          @media only screen and (max-width: $breakpoint-tile-sm) {
            display: none;
          }
        }

        &.centered-md {
          justify-content: center;
        }

        span {
          margin: 2px 0;
        }

        span.product-provider {
          font-size: 14px;
          font-weight: 500;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 16px;

          @media only screen and (max-width: $breakpoint-tile-sm) {
            max-width: 100%;
          }
        }
        span.product-amount {
          font-size: 24px;
          font-weight: 500;
          color: var(--color-primary-500);
          width: 130px;
        }
        span.product-category {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
          border-radius: 16px;
          color: $color-royal-purple;
          border: 1px solid $color-royal-purple;
          width: 120px;

          &.innovation-grant {
            color: var(--color-primary-400);
            border-color: var(--color-primary-300);
          }
        }
        span.product-general-bold {
          font-size: 16px;
          font-weight: 500;
          color: var(--color-primary-500);

          &.quote-expiry {
            color: $color-primary;
          }

          &.instant-eligibility {
            color: var(--color-tertiary-400);
            text-align: center;
            width: 156px;

            @media only screen and (max-width: $breakpoint-tile-md) {
              display: none;
            }
          }
        }
        span.product-general {
          font-size: 14px;
          font-weight: 500;

          &.quote-expiry {
            color: $color-primary;
          }

          &.apr-rates-mobile {
            font-size: 12px;
          }
        }
      }

      .mobile-tile {
        display: flex;
        flex: 1;
        justify-content: space-around;

        @media only screen and (max-width: $breakpoint-tile-sm) {
          margin-top: 20px;
          flex-direction: column;
          align-items: flex-start;
        }

        .product-general {
          white-space: nowrap;
        }

        .product-general-bold {
          @media only screen and (max-width: $breakpoint-tile-sm) {
            padding: 0 10px;
          }
        }

        .mobile {
          @media only screen and (max-width: $breakpoint-tile-sm) {
            flex-direction: row-reverse;
            justify-content: left;
          }
        }
      }

      .view-details.btn {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        height: 32px;
        white-space: nowrap;

        @media only screen and (max-width: $breakpoint-tile-md) {
          margin-left: 0;
          font-size: 14px;
        }

        @media only screen and (max-width: $breakpoint-tile-sm) {
          align-self: flex-start;
          margin-top: 12px;
          margin-left: 0;
        }

        &.has-status {
          background-color: var(--color-primary-100);
          color: $color-primary;
        }
      }

      .link {
        display: flex;
        align-items: center;
        color: $color-primary;
      }
      .material-icons {
        margin: 0 0 0 5px;
      }
    }
  }
}
.mir {
  height: 80px;

  @media only screen and (max-width: $breakpoint-tile-sm) {
    height: 100%;
  }
}
</style>
